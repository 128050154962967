import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllMembers,
  getAnswers,
  sendMember,
  sendQuestionAns,
  sentupdateMember,
  addMembersMembership,
  updateMembersMembership,
  getMemberMembership,
  getMembersMembershipData,
  checkMembershipExists,
  SendEmail,
  ThankyouEmail,
  getMonthlyCollection,
} from "../axiosApi/api";

const initialState = {
  membersData: [],
  membersMembershipData: [],
  answersData: [],
  status: "idle",
  error: null,
  getMembersMembershipData: [],
  selectedMember: {},
  sendMemberDataResponse: "",
  flagReset: null,
  getMonthlyCollection: null,
};

export const fetchMembersData = createAsyncThunk(
  "members/fetchMembersData",
  async (unusedPayload, { dispatch }) => {
    try {
      dispatch(setMembersStatus("loading"));
      const accessToken = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await getAllMembers("/members", { headers });
      dispatch(setMembersStatus("succeeded"));
      return response;
    } catch (error) {
      console.error("Error fetching members data:", error);
      dispatch(setMembersStatus("failed"));
      throw error;
    }
  }
);

export const sendMembersData = createAsyncThunk(
  "members/sendMembersData",
  async (updatedData, emailDetails) => {
    try {
      if (typeof updatedData.id === "undefined") {
        const response = await sendMember(updatedData);
        await sendEmail(emailDetails);

        return response;
      } else {
        const response = await sentupdateMember(updatedData);
        return response;
      }
    } catch (error) {
      throw new Error(
        error.response.data.message || "Failed to send data to the database."
      );
    }
  }
);

export const fetchMonthlyCollectionData = createAsyncThunk(
  "members/MonthlyCollection",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        throw new Error("No access token found");
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await getMonthlyCollection(headers);
      return response;
    } catch (error) {
      console.error("Error in thunk:", error);
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const fetchMemberMembership = createAsyncThunk(
  "members/memberMembership",
  async (memberId, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      console.log("Access Token: ", accessToken);
      if (!accessToken) {
        throw new Error("Access token is missing");
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await getMemberMembership(memberId, {
        headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "An error occurred");
    }
  }
);

export const getMembersMembership = createAsyncThunk(
  "members/getMembersMembershipData",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await getMembersMembershipData(
        `/members/memberMemberships`, // ✅ Corrected URL
        { headers }
      );

      return response; // ✅ No need for `response.data`, it's already extracted
    } catch (error) {
      console.error("Error fetching members membership data:", error);
      return rejectWithValue(error.response?.data || "Something went wrong"); // ✅ Use rejectWithValue for error handling
    }
  }
);

export const addMembersMembershipData = createAsyncThunk(
  "members/addMembersMembershipData",
  async (updatedData) => {
    try {
      const response = await addMembersMembership(updatedData);
      return response;
    } catch (error) {
      throw new Error(
        error.response.data.message || "Failed to send data to the database."
      );
    }
  }
);

export const sendEmail = createAsyncThunk(
  "members/sendMail",
  async (sendDetails, { rejectWithValue }) => {
    try {
      const response = await SendEmail(sendDetails);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Failed to send email.");
    }
  }
);

export const thankyouEmail = createAsyncThunk(
  "members/thankyouMail",
  async (emailDetails, { rejectWithValue }) => {
    try {
      const response = await ThankyouEmail(emailDetails);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Failed to send email.");
    }
  }
);

export const updateMembersMembershipData = createAsyncThunk(
  "members/updateMembersMembershipData",
  async (updatedData) => {
    try {
      if (typeof updatedData.id === "undefined") {
        const response = await updateMembersMembership(updatedData);

        return response;
      } else {
      }
    } catch (error) {
      throw new Error(
        error.response.data.message || "Failed to send data to the database."
      );
    }
  }
);

export const checkMemberMembership = createAsyncThunk(
  "members/CheckMemberMembership",
  async (membershipDetails, { rejectWithValue }) => {
    try {
      const response = await checkMembershipExists(membershipDetails);
      const data = await response.json();
      return data.exists;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchAnswers = createAsyncThunk(
  "answers/fetchAnswers",
  async (unusedPayload, { dispatch }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await getAnswers({ headers });
      return response.data;
    } catch (error) {
      console.error("Error fetching members answers data:", error);
      dispatch(setMembersStatus("failed"));
      throw error;
    }
  }
);

export const sendQuestionsData = createAsyncThunk(
  "questions/sendQeustionsData",
  async (updatedData) => {
    try {
      console.log("member id in memberSlice : ", updatedData.memberid);
      const response = await sendQuestionAns(updatedData);
      return response.data;
    } catch (error) {
      throw new Error(
        error.response.data.message ||
          "Failed to send answers data to the database."
      );
    }
  }
);

export const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setMembersData: (state, action) => {
      state.membersData = action.payload;
    },
    setMembersStatus: (state, action) => {
      state.status = action.payload;
    },
    addMember: (state, action) => {
      state.membersData.push(action.payload);
    },
    setMembersMembershipData: (state, action) => {
      state.membersMembershipData = action.payload;
    },

    updateMember: (state, action) => {
      const updatedMember = action.payload;
      const index = state.membersData.findIndex(
        (member) => member.id === updatedMember.id
      );
      if (index !== -1) {
        state.membersData[index] = updatedMember;
      }
    },
    flagReset: (state, action) => {
      state.sendMemberDataResponse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMembersData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMembersData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.membersData = action.payload;
      })
      .addCase(fetchMembersData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(sendMembersData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendMembersData.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log("action.payload in sendMembersData: ", action.payload);
        state.sendMemberDataResponse = action.payload;
      })
      .addCase(sendMembersData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAnswers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.answersData = action.payload;
        console.log("answers in memberslice: ", state.answersData);
      })
      .addCase(fetchAnswers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAnswers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchMemberMembership.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMemberMembership.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.membersMembershipData = action.payload;
      })
      .addCase(fetchMemberMembership.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getMembersMembership.pending, (state) => {
        state.loading = true;
      })

      .addCase(getMembersMembership.fulfilled, (state, action) => {
        console.log("✅ Forcing State Update");
        state.getMembersMembershipData = action.payload.length
          ? action.payload
          : [{ id: 999, balance_amount: "100" }];
        console.log("payment lenght", action.payload.length);
      })
      .addCase(getMembersMembership.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addMembersMembershipData.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addMembersMembershipData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addMembersMembershipData.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(sendQuestionsData.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(sendQuestionsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendQuestionsData.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(checkMemberMembership.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkMemberMembership.fulfilled, (state, action) => {
        state.status = "sucess";
        state.exists = action.payload;
      })
      .addCase(checkMemberMembership.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })

      .addCase(sendEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.status = "success";
        state.exists = action.payload;
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.status = "Failed to send email.";
        state.error = action.payload;
      })

      .addCase(thankyouEmail.pending, (state) => {
        state.status = "loading";
      })

      .addCase(thankyouEmail.fulfilled, (state, action) => {
        state.status = "success";
        state.exists = action.payload;
      })
      .addCase(thankyouEmail.rejected, (state, action) => {
        state.status = "Failed to send email.";
        state.error = action.payload;
      })
      .addCase(fetchMonthlyCollectionData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMonthlyCollectionData.fulfilled, (state, action) => {
        state.status = "sucess";
        state.exists = action.payload;
        state.getMonthlyCollection = action.payload;
      })
      .addCase(fetchMonthlyCollectionData.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      });
  },
});

export const {
  setMembersData,
  setMembersMembershipData,
  addMember,
  setMembersStatus,
  updateMember,
  flagReset,
} = membersSlice.actions;
export const selectMembersData = (state) => state.members.membersData;
export const selectMembersMembershipData = (state) =>
  state.members.membersMembershipData;
export const selectPartiallyPaidMembers = (state) =>
  state.memberships.partiallyPaid;
export const selectpayment = (state) =>
  state.members.getMembersMembershipData ?? [];

export const selectMembersStatus = (state) => state.members.status;
export const selectMemberById = (state, memberId) => {
  return state.members.membersData.find(
    (member) => member.id === Number(memberId)
  );
};
export const selectAnswerByMemberId = (state, memberId) => {
  return state.members.answersData.filter(
    (member) => member.memberid === Number(memberId)
  );
};
export const sendMemberDataRes = (state) => {
  return state.members.sendMemberDataResponse;
};

export default membersSlice.reducer;
