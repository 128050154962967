export const interestOptions = [
  "Gym",
  "Personal training",
  "Zumba",
  "PT",
  "Gym + Cardio",
  "Yoga",
  "Weight gain",
  "Fat loss",
  "Fitness",
  "All packages",
];

export const sourceofenquiryOptions = ["Walking", "Telephonic", "Email"];

export const enquirytypeOptions = [
  "Visitor",
  "JustDial",
  "Flyers",
  "Hording",
  "Friends",
  "Passing by",
  "Others",
  "Social media",
  "Refrences",
];

export const paymentOptions = ["Phonepay", "Gpay", "Upi", "Cash"];
const currentDate = new Date();
const year = currentDate.getFullYear();
const month = currentDate.getMonth() + 1;
const day = currentDate.getDate();
export const enquiryDate = `${day}-${month}-${year}`;
