import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Switch,
  Grid,
  Typography,
  Chip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AnalyticCard from "../cards/AnalyticCard";
import MainCard from "../cards/MainCard";
import {
  fetchMembershipsData,
  selectMembershipsData,
} from "../../../../slices/membershipsSlice";

export default function Memberships() {
  const dispatch = useDispatch();
  const membershipsData = useSelector(selectMembershipsData);
  const [membershipsRows, setMembershipsRows] = useState([]);
  const [isActive, setIsActive] = useState(false); // Added state for switch
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Calculate total and active memberships
  const totalMemberships = membershipsData.length;
  const activeMemberships = membershipsData.filter(
    (membership) => membership.status
  ).length;

  const membershipsColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 180,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: "term_months",
      headerName: "Duration (months)",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 150,
    },
    {
      field: "fee",
      headerName: "Fee",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 120,
    },
    {
      field: "status",
      headerName: "Status",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 120,
      renderCell: (params) => {
        const status = params.row.status;
        return status ? (
          <Chip label="Active" color="success" />
        ) : (
          <Chip label="Inactive" color="error" />
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchMembershipsData());
  }, [dispatch]);

  useEffect(() => {
    if (membershipsData) {
      const filteredData = isActive
        ? membershipsData
        : membershipsData.filter((membership) => membership.status);
      setMembershipsRows(filteredData);
    }
  }, [membershipsData, isActive]);

  const addNewMembership = () => {
    navigate(`/adminapp/membershipDetails/`);
  };

  const handleRowClick = (params) => {
    const selectedMembershipId = params.row.id;
    navigate(`/adminapp/membershipDetails/${selectedMembershipId}`);
  };

  const handleToggle = (checked) => {
    setIsActive(checked);
  };

  return (
    <Grid
      container
      rowSpacing={4.5}
      columnSpacing={2.75}
      sx={{ background: "#fafafb" }}
    >
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Memberships</Typography>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            variant="contained"
            startIcon={<PersonAddIcon />}
            onClick={addNewMembership}
          >
            New Membership
          </Button>
        </ButtonGroup>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title="Total Memberships"
          count={totalMemberships}
          extra="5"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title="Active Memberships"
          count={activeMemberships}
          extra="5"
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Memberships Details</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={isActive}
                  onChange={(e) => handleToggle(e.target.checked)}
                  color="primary"
                  inputProps={{ "aria-label": "toggle switch" }}
                />
              }
              label={isActive ? "All Memberships" : "Active Memberships"}
            />
          </Grid>
        </Grid>
        <MainCard sx={{ mt: 2 }} content={false}>
          <Box sx={{ height: 400, width: 1 }}>
            <DataGrid
              columns={membershipsColumns}
              rows={membershipsRows}
              pageSize={50}
              onRowClick={handleRowClick}
              initialState={{
                sorting: {
                  sortModel: [{ field: "id", sort: "desc" }],
                },
              }}
            />
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
}
