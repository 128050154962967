import axios from "axios";
import Members from "../components/admin/pages/members/Members";

//const BASE_URL = "http://localhost:3400";
const BASE_URL = "https://api.capstonegym.com";

export const sendEnquiry = async (formData) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(`${BASE_URL}/enquiries`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const sentupdateEnquiry = async (formData) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}/enquiries/updateEnquiry`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(
      "Error updating enquiry:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const getAllEnquiry = async (url, config) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${BASE_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...config?.headers,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendMember = async (formData) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(`${BASE_URL}/members`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const sentupdateMember = async (formData) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}/members/updateMember`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getMembersMembershipData = async (url, config) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${BASE_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...config?.headers,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addMembersMembership = async (formData) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}/members/addMembersMembership`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const SendEmail = async (emailDetails) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}/members/sendMail`,
      emailDetails,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const ThankyouEmail = async (emailDetails) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}/members/thankyouMail`,
      emailDetails,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateMembersMembership = async (formData) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}/members/updateMemberMembership`,
      formData,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (error) {
    console.error(
      "Error updating membership:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const getAllMembers = async (url, config) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${BASE_URL}${url}`, config, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...config?.headers,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getinvoice = async (url, config) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${BASE_URL}${url}`, config, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...config?.headers,
      },
    });
    console.log("Fetched Invoice Data:", response.data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMonthlyCollection = async (headers) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${BASE_URL}/members/MonthlyCollection`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching monthly collection:", error);
    throw error;
  }
};

export const getMemberMembership = async (memberId) => {
  let token = localStorage.getItem("accessToken");

  try {
    const response = await axios.get(`${BASE_URL}/members/memberMembership`, {
      params: { memberId: memberId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(
      "Error fetching membership details:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const checkMembershipExists = async ({ ...membershipDetails }) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(
      `/members/CheckMemberMembership/`,
      membershipDetails,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.exists;
  } catch (error) {
    console.error(
      "Error fetching membership details:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// questions
export const getAllQuestions = async (config) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${BASE_URL}/members/questions`, config, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...config?.headers,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendQuestionAns = async (formData, memId) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}/members/questions`,
      { formData, memId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAnswers = async (config) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${BASE_URL}/members/answer`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...config?.headers,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
// trainer
export const sendTrainer = async (formData) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(`${BASE_URL}/trainers`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const sentupdateTrainer = async (formData) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}/trainers/updateTrainer`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllTrainers = async (url, config) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${BASE_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...config?.headers,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllMemberships = async (url, config) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${BASE_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...config?.headers,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addUpdateMembershipService = async (url, postData) => {
  let token = localStorage.getItem("accessToken");
  try {
    const response = await axios.post(`${BASE_URL}${url}`, postData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const login = async (loginData) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, loginData);
    return response.data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const sendForgotPasswordEmail = async (email) => {
  try {
    const response = await axios.post(`${BASE_URL}/forgot-password`, {
      email,
    });

    return response.data;
  } catch (error) {
    console.error("Error sending reset password email:", error);
    throw error;
  }
};

export const register = async (registerData) => {
  try {
    const response = await axios.post(`${BASE_URL}/register`, registerData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
