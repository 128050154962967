import { React, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Menu,
  Avatar,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const AvatarDropDown = () => {
  const settings = ["Profile", "Dashboard", "Logout"];
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogOut = () => {
    // alert("Logging Out");
    handleCloseUserMenu();
  };
  const handleOption = (option) => {
    if (option === "Logout") {
      navigate("/");
      handleLogOut();
    } else if (option === "Profile") {
      navigate("/adminapp/adminprofile");
      handleCloseUserMenu();
    } else if (option === "Dashboard") {
      navigate("/adminapp");
      // alert("Opening Dashboard");
      handleCloseUserMenu();
    } else {
      handleCloseUserMenu();
    }
  };

  return (
    <Grid container justifyContent="flex-end">
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar
              alt="Remy Sharp"
              src="loid.jpeg"
              sx={{ width: 56, height: 56 }}
            />
          </IconButton>
        </Tooltip>

        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {[
            settings.map((setting) => (
              <>
                <MenuItem
                  key={setting}
                  onClick={() => {
                    handleOption(setting);
                  }}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              </>
            )),
          ]}
        </Menu>
      </Box>
    </Grid>
  );
};
export default AvatarDropDown;
