import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  Button,
  Avatar,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Stack,
  Snackbar,
  Alert,
  Chip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import jsPDF from "jspdf";
import DownloadIcon from "@mui/icons-material/Download";
import { paymentOptions } from "../../../quickEnquiry/constant";
import CustomDialog from "../../../common/CustomDialog";
import ResponsiveTableRow from "../../../common/ResponsiveTableRow";
import { selectTrainersData } from "../../../../slices/trainersSlice";
import {
  selectMembershipById,
  selectMembershipsData,
  flagReset,
  sendMembershipData,
  sendMembershipDataRes,
  fetchMembershipsData,
} from "../../../../slices/membershipsSlice";
import {
  addMembersMembershipData,
  fetchMemberMembership,
  updateMembersMembershipData,
  checkMemberMembership,
  selectMembersMembershipData,
  cellTwoNamehankyouEmail,
  sendEmail,
  thankyouEmail,
} from "../../../../slices/membersSlice";
import { sendMemberMembership } from "../../../../axiosApi/api";
import {
  invoiceData,
  selectInvoiceData,
} from "../../../../slices/enquirySlice";
export default function MemberMembershipDetails(props) {
  const { memberId } = props;
  const { member } = props;
  const { selectedMember } = props;
  const { onStatusUpdate } = props;
  const { membershipsData } = props;
  const dispatch = useDispatch();
  const [tabsHeadValue, setTabsValueHead] = useState("1");
  const [editcud, setEditcud] = useState({
    save: false,
    cancel: false,
  });
  const navigate = useNavigate();
  const [paymentMode, setPaymentMode] = useState("");
  const [identifyDialog, setIdentifyDialog] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(false);
  const [handleUpdate, setHandleUpdate] = useState(false);
  const [isMembershipAdded, setIsMembershipAdded] = useState(false);
  const [isNewMember, setIsNewMember] = useState(true);
  const [membershipDetails, setMembershipDetails] = useState({
    memberId: memberId,
    membership: "",
    trainer: "",
    startDate: "",
    endDate: "",
    paidAmount: 0,
    balanceAmount: 0,
    totalAmount: 0,
    paidNow: 0,
    paymentMode: "",
  });
  const [errors, setErrors] = useState({
    membership: "",
    trainer: "",
    startDate: "",
    endDate: "",
    paidAmount: "",
    balanceAmount: "",
    totalAmount: "",
    paidNow: "",
    paymentMode: "",
  });
  const [editableFields, setEditableFields] = useState(false);
  const [memberships, setMemberships] = useState([]);

  // const [paidNow, setPaidNow] = useState("0");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const selectedMembership = useSelector((state) =>
  //   selectMembershipById(state, memberId)
  // );
  const [selectedMembershipDetails, setSelectedMembershipDetails] = useState(
    {}
  );
  const trainersData = useSelector(selectTrainersData);
  const trainers = trainersData.map((trainer) => {
    return { id: trainer.id, name: trainer.name };
  });

  const getTrainerName = (trainerId) => {
    const trainer = trainers.find((trainer) => trainer.id === trainerId);
    console.log("Matching Trainer:", trainer);
    return trainer ? trainer.name : `Trainer with ID ${trainerId} not found`;
  };

  const trainerName = getTrainerName(membershipDetails.trainer);
  console.log("Trainer Name:", trainerName);

  const selectedMembership = membershipsData.find(
    (membership) => membership.id === membershipDetails.membership
  );

  const membersMembershipData = useSelector(selectMembersMembershipData);

  const invoiceDataState = useSelector(selectInvoiceData);
  const invoiceNumber =
    invoiceDataState[0]?.[" invoice_no"]?.toString().trim() || "N/A";
  console.log(invoiceNumber);

  const [receiptNumber, setReceiptNumber] = useState(() => {
    return Number(localStorage.getItem("receiptNumber")) || 1; // Load from localStorage
  });

  useEffect(() => {
    dispatch(invoiceData());
  }, []); // Re-run effect if status changes

  useEffect(() => {
    if (memberId) {
      dispatch(fetchMemberMembership(memberId));
    }
  }, [memberId]);

  useEffect(() => {
    dispatch(invoiceData()).then((data) => {
      console.log("Fetched Invoice Data:", data);
    });
  }, []);

  useEffect(() => {
    setMemberships(
      membershipsData.map((membership) => {
        return {
          id: membership.id,
          name: membership.name,
          term_months: membership.term_months,
          fee: membership.fee,
        };
      })
    );
  }, [membershipsData]);

  const [isUpdateEnabled, setIsUpdateEnabled] = useState(true);

  useEffect(() => {
    setMembershipDetails(() => ({
      // ...membershipDetails,
      memberId: memberId,
      membership: membersMembershipData?.membership_id,
      trainer: membersMembershipData?.trainer_id,
      startDate:
        membersMembershipData?.membership_startdate === undefined
          ? ""
          : membersMembershipData?.membership_startdate,
      endDate:
        membersMembershipData?.membership_enddate === undefined
          ? ""
          : membersMembershipData?.membership_enddate,
      paidAmount: membersMembershipData?.paid_amount,
      balanceAmount: membersMembershipData?.balance_amount,
      totalAmount: membersMembershipData?.fee,
      paidNow: membersMembershipData?.paidNow,
      paymentMode: membersMembershipData?.payment_mode,
      // totalAmount: memberships.find(
      //   (membership) => membership.id === membersMembershipData?.membership_id
      // ).fee,
    }));

    if (membersMembershipData?.membership_id) {
      const days = 7;

      if (
        isDateLessThanGivenDaysFromToday(
          membersMembershipData?.membership_enddate,
          days
        ) ||
        membersMembershipData?.balance_amount !== 0
      ) {
        setEditcud({ save: false, update: true });
      } else {
        setEditcud({ save: false, update: false });
      }
      setEditableFields(false);
    } else {
      setEditcud({ save: true, update: false });
      setEditableFields(true);
    }
  }, [membersMembershipData, memberId]);

  function isDateLessThanGivenDaysFromToday(dateToCheck, days) {
    const today = new Date();
    const d1 = new Date(dateToCheck);

    const diffInMs = Math.abs(d1 - today);

    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    if (diffInDays < days) {
      return true;
    }
    return false;
  }

  const handleInputChange = (field, value, comment) => {
    if (errors[field]) {
      const removeError = Object.fromEntries(
        Object.entries(errors).filter(([k]) => k !== field)
      );
      setErrors(removeError);
    }

    if (field === "paidNow") {
      const regex = /^[0-9]*$/; // Allow only numeric input

      if (comment === "onBlur") {
        if (
          regex.test(value) &&
          value.length <= 6 &&
          parseFloat(value) <= parseFloat(membershipDetails.balanceAmount)
        ) {
          setMembershipDetails((prev) => ({
            ...prev,
            [field]: value,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [field]: "Invalid amount.",
          }));
        }
      } else {
        if (regex.test(value) && value.length <= 6) {
          setMembershipDetails((prev) => ({
            ...prev,
            [field]: value,
          }));
        }
      }
    } else if (field === "membership") {
      const membershipTemp = memberships.find(
        (membership) => membership.id === value
      );
      setSelectedMembershipDetails(membershipTemp);
      setMembershipDetails((prev) => ({
        ...prev,
        [field]: value,
        paidAmount: 0,
        balanceAmount: membershipTemp.fee,
        totalAmount: membershipTemp.fee,
        startDate: "",
        endDate: "",
      }));
    } else if (field === "startDate") {
      if (selectedMembershipDetails) {
        const startDate = value ? new Date(value) : new Date();
        const tempStartDate = new Date(startDate);
        const newEndDate = new Date(
          tempStartDate.setMonth(
            tempStartDate.getMonth() + selectedMembershipDetails.term_months
          )
        );

        setMembershipDetails((prev) => ({
          ...prev,
          startDate: startDate,
          endDate: newEndDate,
        }));
      }
    } else {
      setMembershipDetails((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const validateFields = () => {
    const newErrors = {};

    if (!membershipDetails.membership)
      newErrors.membership = "Membership is required.";
    if (!membershipDetails.trainer) newErrors.trainer = "Trainer is required.";
    if (!membershipDetails.startDate) {
      newErrors.startDate = "Start date is required.";
    }
    return newErrors;
  };

  const generatePDFContent = (
    doc,
    imgBase64,
    selectedMember,
    membersMembershipData,
    selectedMembership,
    trainerName,
    updatedReceiptNumber
  ) => {
    doc.addImage(imgBase64, "PNG", 10, 10, 30, 30);

    doc.setFontSize(12).setFont("helvetica", "bold");
    doc.text("CAPSTONE SPORTS AND FITNESS-Pradhikaran", 50, 20);

    doc.setFontSize(8).setFont("helvetica", "normal");
    const headerInfo = [
      "Sector 27/A, Plot No. 209,",
      "Near Sambhaji Chowk, Pradhikaran, Pune 411044",
      "Pradhikaran",
      "8888188855",
    ];
    headerInfo.forEach((line, index) => doc.text(line, 50, 25 + index * 5));

    const invoiceDate = new Date().toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    doc.setFontSize(10).setFont("helvetica", "bold");
    doc.text("Invoice Date:", 158, 20);
    doc.setFont("helvetica", "normal");
    doc.text(invoiceDate, 180, 20);

    doc.line(10, 45, 200, 45);

    doc.setFontSize(10).setFont("helvetica", "normal");
    const memberDetails = [
      `Id: ${selectedMember?.id || "N/A"}`,
      `Name: ${selectedMember?.name || "N/A"}`,
      `Contact: ${selectedMember?.contact_no || "N/A"}`,
      `Email: ${selectedMember?.email_id || "N/A"}`,
      `GST No: ${selectedMember?.gst_number || "N/A"}`,
    ];
    memberDetails.forEach((detail, idx) => {
      doc.text(detail, idx < 3 ? 10 : 100, 50 + (idx % 3) * 5);
    });

    doc.line(10, 65, 200, 65);

    const formatDate = (dateString) => {
      if (!dateString) return "N/A";
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB");
    };
    const time = "10AM";
    const discount = "-"; // Discount value (fixed at 100 as per the requirement)
    const fee = "-";
    // const paidAmount=
    // Table Section
    const tableData = [
      [
        "Package",
        selectedMembership?.name || "N/A",
        "Amount",
        selectedMembership?.fee || "N/A",
      ],
      [
        "Duration",
        selectedMembership?.term_months || "N/A",
        "Discount",
        discount || "N/A",
      ],
      [
        "Start Date",
        formatDate(membersMembershipData?.membership_startdate) || "N/A",
        "Reg Fees",
        fee || "N/A",
      ],
      [
        "End Date",
        formatDate(membersMembershipData?.membership_enddate) || "N/A",
        "Total Amount",
        membershipDetails?.totalAmount || "N/A",
      ],
      [
        "Time",
        time || "N/A",
        "Paid Amount",
        membershipDetails?.paidAmount || "N/A",
      ],
      [
        "Instructor",
        trainerName || "N/A",
        "Balance",
        // membersMembershipData?.balance_amount || "N/A",
        membershipDetails?.balanceAmount || "N/A",
      ],
    ];

    const startX = 10;
    const startY = 70;
    const cellWidth = 47.5;
    const cellHeight = 10;

    tableData.forEach((row, rowIndex) => {
      const rowY = startY + rowIndex * cellHeight;
      row.forEach((cellText, colIndex) => {
        doc.text(
          cellText.toString(),
          startX + colIndex * cellWidth + 5,
          rowY + 5
        );
        doc.rect(startX + colIndex * cellWidth, rowY, cellWidth, cellHeight);
      });
    });
    doc.line(10, 130, 200, 130);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("Payment Transaction:", 10, 135);

    const transactionData = [
      [
        "Receipt No",
        "Date",
        "Subtotal",
        "Tax",
        "CGST",
        "SGST",
        "Tax Amt",
        "Paid Amt",
        "Mode",
        "Exec",
      ],
      [
        updatedReceiptNumber || "N/A",
        invoiceDate || "N/A",
        selectedMembership?.fee || "N/A",
        selectedMember?.tax || "N/A",
        selectedMember?.cgst || "N/A",

        selectedMember?.sgst || "N/A",
        selectedMember?.tax_amount || "N/A",
        membershipDetails?.paidAmount || "N/A",
        selectedMember?.payment_mode || "N/A",
        selectedMember?.executive || "N/A",
      ],
    ];

    const paymentCellWidth = 20;
    const paymentCellHeight = 10;
    let paymentStartY = 140;

    doc.setFont("helvetica", "normal");
    transactionData.forEach((row, rowIndex) => {
      const rowY = paymentStartY + rowIndex * paymentCellHeight;
      row.forEach((cellText, colIndex) => {
        const cellX = startX + colIndex * paymentCellWidth;
        const textX = cellX + paymentCellWidth / 2;
        const textY = rowY + paymentCellHeight / 2 + 1.5;

        doc.text(cellText.toString(), textX, textY, { align: "center" });
        doc.rect(cellX, rowY, paymentCellWidth, paymentCellHeight);
      });
    });

    const addTermsAndConditions = (doc) => {
      const termsStartY = 170;
      const terms = [
        "1) Members with any health complaints are required to provide a doctor’s certificate before participating.",
        "2) Members assume full responsibility for any injuries or health issues incurred during their use of gym facilities.",
        "3) Members are responsible for the cost of any damage they cause to gym property.",
        "4) The gym is not liable for the loss of personal valuables or belongings.",
        "5) Membership fees are strictly non-refundable and non-transferable.",
        "6) Memberships cannot be frozen, extended, or transferred under any circumstances.",
        "7) Members are required to bring appropriate sportswear, shoes, a napkin, and a water bottle for their sessions.",
      ];

      doc.setFontSize(12).setFont("helvetica", "bold");
      doc.text("Terms and Conditions:", 15, termsStartY);

      doc.setFontSize(10).setFont("helvetica", "normal");
      terms.forEach((term, index) =>
        doc.text(term, 15, termsStartY + 5 + index * 5)
      );
    };
    addTermsAndConditions(doc);
  };

  const createMemberPDF = async (
    selectedMember,
    selectedMembership,
    membersMembershipData,
    trainerName,
    updatedReceiptNumber
  ) => {
    const imgPath = "/capstone512.png";
    const imgElement = new Image();
    imgElement.src = imgPath;

    return new Promise((resolve, reject) => {
      imgElement.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = imgElement.width;
        canvas.height = imgElement.height;
        context.drawImage(imgElement, 0, 0);

        const imgBase64 = canvas.toDataURL("image/png");
        const doc = new jsPDF();

        generatePDFContent(
          doc,
          imgBase64,
          selectedMember,
          membersMembershipData,
          selectedMembership,
          trainerName,
          updatedReceiptNumber
        );
        resolve(doc);
      };

      imgElement.onerror = () => {
        reject("Failed to load image at the specified path");
      };
    });
  };

  const handleDownload = async (
    selectedMember,
    selectedMembership,
    membersMembershipData,
    trainerName,
    receiptNumber
  ) => {
    try {
      setReceiptNumber((prevReceipt) => {
        const updatedReceiptNumber = prevReceipt + 1; // Ensure +1 increase
        return updatedReceiptNumber;
      });
      const updatedReceiptNumber = receiptNumber + 1;
      const pdfDoc = await createMemberPDF(
        selectedMember,
        selectedMembership,
        membersMembershipData,
        trainerName,
        updatedReceiptNumber
      );
      pdfDoc.save("Member_Details.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleUpdateClick = async () => {
    if (!isUpdateEnabled) {
      setNotification({
        message:
          "Update is not allowed. Membership can only be updated 7 days before expiry.",
        variant: "warning",
      });
      return;
    }
    setLoading(true);

    try {
      setEditableFields(true);

      // const updateDetails = {
      //   memberId: memberId,
      //   email_id: member.email_id,
      //   phone_no: member.contact_no,
      //   startDate: membershipDetails.startDate,
      //   endDate: membershipDetails.endDate,
      //   membership: membershipDetails.membership,
      //   trainer: membershipDetails.trainer,
      //   paidAmount: parseFloat(membersMembershipData?.paidamount),
      //   balanceAmount: parseFloat(membersMembershipData?.balance_amount),
      //   totalAmount: membershipDetails.totalAmount,
      //   receiptNumber: invoiceNumber,
      // };
      // await dispatch(updateMembersMembershipData(updateDetails)).unwrap();

      // enableUpdateIfBalanceIsNotZero();
      // setNotificationMessageAndEditableFields(
      //   "Membership updated successfully!",
      //   false
      // );
      // const emailDetails = {
      //   memberId: memberId,
      //   email_id: member.email_id,
      //   phone_no: member.contact_no,
      //   membership_startdate: membershipDetails.startDate,
      //   membership_enddate: membershipDetails.endDate,
      //   MembershipId: membershipDetails.membership,
      //   TrainerId: membershipDetails.trainer,
      //   paidAmount: parseFloat(membersMembershipData?.paidamount),
      //   balanceAmount: parseFloat(membersMembershipData?.balance_amount),
      //   totalAmount: membershipDetails.totalAmount,
      //   receiptNumber: invoiceNumber,
      // };
      // await dispatch(thankyouEmail(emailDetails)).unwrap();
      // setIsUpdateEnabled(false);
      setEditcud({
        save: true,
        cancel: true,
      });
    } catch (error) {
      console.error("Error fetching membership details: ", error);
      setNotification({
        message: error.message || "Failed to fetch membership details.",
        variant: "error",
      });
      setOpenNotification(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setDialogOpen(true);
    setIdentifyDialog(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleConfirmForCancel = () => {
    if (props === "add") {
      setMembershipDetails({
        memberId: memberId,
        membership: "",
        trainer: "",
        startDate: "",
        endDate: "",
      });
      setEditableFields(true);
      setEditcud({ save: true, cancel: true, update: false });
      setErrors({});
    } else {
      dispatch(fetchMemberMembership(memberId));
      setEditableFields(false);
      setEditcud({ save: false, cancel: false, update: true });
    }
    setDialogOpen(false);
  };
  const handleConfirmForBacktoMembership = () => {
    navigate("/adminapp/memberships");
  };
  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotification(false);
  };
  const handleBacktoMember = () => {
    navigate("/adminapp/members");
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();

    // Validate form fields
    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setLoading(true); // Start loading
    setErrors({}); // Clear previous errors

    setMembershipDetails((prev) => ({
      ...prev,
      balanceAmount:
        membershipDetails?.balanceAmount !== 0 &&
        membershipDetails?.balanceAmount !== undefined
          ? parseFloat(membershipDetails?.balanceAmount) -
            parseFloat(membershipDetails?.paidNow)
          : 0,
      paidAmount:
        membershipDetails?.paidAmount !== 0 &&
        membershipDetails?.paidAmount !== undefined
          ? parseFloat(membershipDetails?.paidAmount) +
            parseFloat(membershipDetails?.paidNow)
          : parseFloat(membershipDetails?.paidAmount),
    }));

    const membershipDetailsParams = {
      ...membershipDetails,
      balanceAmount:
        membershipDetails?.balanceAmount !== 0 &&
        membershipDetails?.balanceAmount !== undefined
          ? parseFloat(membershipDetails?.balanceAmount) -
            parseFloat(membershipDetails?.paidNow)
          : 0,
      paidAmount:
        membershipDetails?.paidAmount !== 0 &&
        membershipDetails?.paidAmount !== undefined
          ? parseFloat(membershipDetails?.paidAmount) +
            parseFloat(membershipDetails?.paidNow)
          : parseFloat(membershipDetails?.paidAmount),
      memberId,
    };

    setLoading(true);

    setErrors({}); // Clear previous errors

    try {
      if (Object.keys(membersMembershipData).length > 0) {
        const updateDetails = {
          memberId: memberId,
          email_id: member.email_id,
          phone_no: member.contact_no,
          startDate: membershipDetails.startDate,
          endDate: membershipDetails.endDate,
          membership: membershipDetails.membership,
          trainer: membershipDetails.trainer,
          paidAmount: membershipDetails?.paidNow
            ? parseFloat(membershipDetails?.paidAmount) +
              parseFloat(membershipDetails?.paidNow)
            : parseFloat(membershipDetails?.paidAmount),
          balanceAmount: membershipDetails?.paidNow
            ? parseFloat(membershipDetails?.balanceAmount) -
              parseFloat(membershipDetails?.paidNow)
            : parseFloat(membershipDetails?.balanceAmount),
          totalAmount: membershipDetails.totalAmount,
          paymentMode: membershipDetails.paymentMode,
          receiptNumber: invoiceNumber,
        };
        const emailDetails = {
          ...updateDetails,
          emailType: "update",
        };
        await dispatch(updateMembersMembershipData(emailDetails)).unwrap();
        await dispatch(thankyouEmail(emailDetails)).unwrap();
        enableUpdateIfBalanceIsNotZero();
        setNotificationMessageAndEditableFields(
          "Membership updated successfully!",
          false
        );
      } else {
        await dispatch(
          addMembersMembershipData(membershipDetailsParams)
        ).unwrap();

        if (
          isDateLessThanGivenDaysFromToday(
            membersMembershipData?.membership_enddate
          )
        ) {
          const sendDetails = {
            memberId: memberId,
            email_id: member.email_id,
            phone_no: member.contact_no,
            membership_enddate: membersMembershipData?.membership_enddate,
          };
          await dispatch(sendEmail(sendDetails)).unwrap();
        }

        // setLoading(false);
        enableUpdateIfBalanceIsNotZero();

        setNotificationMessageAndEditableFields(
          "Membership added successfully!",
          false
        );

        const Details = {
          memberId: memberId,
          email_id: member.email_id,
          phone_no: member.contact_no,
          membership_startdate: membershipDetails.startDate,
          membership_enddate: membershipDetails.endDate,
          membership: membershipDetails.membership,
          TrainerId: membershipDetails.trainer,
          paidAmount:
            parseFloat(membershipDetails?.paidAmount) +
            parseFloat(
              isNaN(membershipDetails?.paidNow) ? 0 : membershipDetails?.paidNow
            ),
          balanceAmount:
            parseFloat(membershipDetails?.balanceAmount) -
            parseFloat(
              isNaN(membershipDetails?.paidNow) ? 0 : membershipDetails?.paidNow
            ),
          totalAmount: membershipDetails.totalAmount,
          receiptNumber: invoiceNumber,
        };

        const eDetails = {
          ...Details,
          emailType: "new",
        };

        const emailSent = await dispatch(thankyouEmail(eDetails)).unwrap();
        if (emailSent) {
          onStatusUpdate(true);
        }

        setNotification({
          message: "Membership saved successfully!",
          variant: "success",
        });

        setEditableFields(false);
      }
    } catch (error) {
      setNotification({
        message: error?.response?.data?.message || "Failed to save data.",
        variant: "error",
      });
      console.error("Error saving membership:", error.message);
    } finally {
      setLoading(false); // Stop loading after API call
      setOpenNotification(true); // Show notification
    }
  };

  function setNotificationMessageAndEditableFields(message, editablFlag) {
    setNotification({
      message: message,
      variant: "success",
    });
    setEditableFields(editablFlag);
  }
  function enableUpdateIfBalanceIsNotZero() {
    if (membershipDetails.balanceAmount !== 0) {
      setEditcud({ save: false, cancel: false, update: true });
    } else {
      setEditcud({ save: false, cancel: false, update: false });
    }
  }

  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Stack spacing={2} direction={{ xs: "column", sm: "row", md: "row" }}>
          <Button
            variant="contained"
            size="small"
            startIcon={!loading && <EditIcon />}
            onClick={handleUpdateClick}
            disabled={!editcud.update || loading}
          >
            {loading ? "Updating..." : "Update Membership"}
          </Button>

          {editcud.cancel ? (
            <Button
              variant="contained"
              size="small"
              startIcon={<CancelIcon />}
              onClick={handleCancelClick}
            >
              {props.mode === "add" ? "Clear" : "Cancel"}
            </Button>
          ) : (
            " "
          )}
          {isSmallScreen ? (
            " "
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Button
                variant="contained"
                size="small"
                startIcon={!loading && <SaveIcon />}
                onClick={handleSaveClick}
                disabled={!editcud.save || loading}
              >
                {loading ? "Saving..." : "Save Details"}
              </Button>
            </div>
          )}
          {loading && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1300,
              }}
            >
              <CircularProgress />
            </div>
          )}
          <Button
            id="Button_size"
            variant="contained"
            size="small"
            startIcon={<DownloadIcon />}
            onClick={() =>
              handleDownload(
                selectedMember,
                selectedMembership,
                membersMembershipData,
                trainerName,
                receiptNumber
              )
            }
            disabled={member.status === false}
          >
            Download
          </Button>

          {identifyDialog ? (
            <CustomDialog
              open={dialogOpen}
              handleClose={handleDialogClose}
              onConfirm={handleConfirmForCancel}
              content={"Do you want to Discard Changes ?"}
            />
          ) : (
            <CustomDialog
              open={dialogOpen}
              handleClose={handleDialogClose}
              onConfirm={handleConfirmForBacktoMembership}
              content={
                "Some of the details are unsaved. Do you want to go back without saving ?"
              }
            />
          )}

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={openNotification}
            autoHideDuration={6000}
            onClose={handleNotificationClose}
          >
            <Alert
              onClose={handleNotificationClose}
              severity={notification.variant}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {notification.message}
            </Alert>
          </Snackbar>
        </Stack>
        <Stack direction="row" sx={{ mt: { xs: "1rem", sm: "0" } }}>
          <Button
            id="Button_size"
            variant="contained"
            size="small"
            startIcon={<PersonIcon />}
            onClick={handleBacktoMember}
          >
            Back to Members
          </Button>
        </Stack>
      </Grid>
      <Grid
        container
        justifyContent={{ xs: "flex-start", sm: "flex-end" }}
        sx={{ mt: 2 }}
      >
        {membersMembershipData?.balance_amount !== undefined && (
          <>
            {parseFloat(membershipDetails?.balanceAmount) === 0 ? (
              <Typography
                variant="body2"
                sx={{ color: "green", fontWeight: "bold" }}
              >
                Fully Paid
              </Typography>
            ) : parseFloat(membersMembershipData?.balance_amount) > 0 ? (
              <Typography
                variant="body2"
                sx={{ color: "orange", fontWeight: "bold" }}
              >
                Partially Paid
              </Typography>
            ) : null}
          </>
        )}
      </Grid>

      <Divider sx={{ my: 3 }} />

      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            <ResponsiveTableRow
              errorCellOne={errors.membership}
              errorCellTwo={errors.trainer}
              memberFieldCellOne={
                memberships.find(
                  (membership) =>
                    membership.id === membershipDetails?.membership
                )?.name
              }
              memberFieldCellOneValue={membershipDetails?.membership}
              memberFieldCellTwo={
                trainers.find(
                  (trainer) => trainer.id === membershipDetails?.trainer
                )?.name
              }
              memberFieldCellTwoValue={membershipDetails?.trainer}
              memberFieldCellOneOptions={memberships}
              memberFieldCellTwoOptions={trainers}
              memberFieldCellOneType="dropdown"
              memberFieldCellTwoType="dropdown"
              isSmallScreen={isSmallScreen}
              fieldOneEditable={editableFields}
              fieldTwoEditable={editableFields}
              cellOneName="Membership"
              cellTwoName="Trainer"
              fieldOneName="membership"
              fieldTwoName="trainer"
              handleInputChange={handleInputChange}
            />
            <ResponsiveTableRow
              errorCellOne={errors.startDate}
              errorCellTwo={errors.endDate}
              memberFieldCellOne={membershipDetails?.startDate}
              memberFieldCellTwo={membershipDetails?.endDate}
              memberFieldCellOneType="date"
              memberFieldCellTwoType="date"
              isSmallScreen={isSmallScreen}
              fieldOneEditable={editableFields}
              fieldTwoEditable={false}
              cellOneName="Membership Start Date"
              cellTwoName="Membership End Date"
              fieldOneName="startDate"
              fieldTwoName="endDate"
              handleInputChange={handleInputChange}
            />
            <ResponsiveTableRow
              errorCellOne={errors.paidAmount}
              memberFieldCellOne={membershipDetails?.paidAmount}
              memberFieldCellTwo={membershipDetails?.balanceAmount}
              memberFieldCellOneType="text"
              memberFieldCellTwoType="text"
              isSmallScreen={isSmallScreen}
              fieldOneEditable={false}
              fieldTwoEditable={false}
              cellOneName="Paid Amount"
              cellTwoName="Balance Amount"
              fieldOneName="paidAmount"
              fieldTwoName="balanceAmount"
              handleInputChange={handleInputChange}
            />
            <ResponsiveTableRow
              errorCellOne={errors.totalAmount}
              errorCellTwo={errors.paidNow}
              memberFieldCellOne={membershipDetails?.totalAmount}
              memberFieldCellTwo={membershipDetails?.paidNow}
              memberFieldCellOneType="text"
              memberFieldCellTwoType="text"
              isSmallScreen={isSmallScreen}
              fieldOneEditable={false}
              fieldTwoEditable={editableFields}
              cellOneName="Total Membership Amount"
              cellTwoName="Paid Now"
              fieldOneName="totalAmount"
              fieldTwoName="paidNow"
              handleInputChange={handleInputChange}
            />
            <ResponsiveTableRow
              errorCellOne={errors.paymentMode}
              memberFieldCellOne={membershipDetails?.paymentMode}
              memberFieldCellOneValue={membershipDetails?.paymentMode}
              memberFieldCellOneOptions={paymentOptions}
              memberFieldCellOneType="dropdown"
              isSmallScreen={isSmallScreen}
              fieldOneEditable={editableFields}
              cellOneName="Payment Mode"
              fieldOneName="paymentMode"
              handleInputChange={handleInputChange}
            />
          </TableBody>
        </Table>
        <Grid sx={{ mt: 3, color: "red" }}>
          <Typography>
            Note: Membership can be updated only before 7 days of End date.
          </Typography>
        </Grid>
      </TableContainer>

      {isSmallScreen ? (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 3 }}
        >
          <Button
            disabled={!editcud.save}
            variant="contained"
            size="small"
            startIcon={<SaveIcon />}
            onClick={editableFields ? handleSaveClick : handleUpdateClick}
          >
            {editableFields ? "Save Changes" : "Update Membership"}
          </Button>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}
