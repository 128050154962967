import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchMembersData,
  selectMembersData,
  selectMembersStatus,
  getMembersMembership,
  selectMembersMembershipData,
  fetchMonthlyCollectionData,
  selectPartiallyPaidMembers,
  selectpayment,
} from "../../../../slices/membersSlice";
import { getMembersMembershipData } from "../../../../axiosApi/api";
import {
  fetchMembershipsData,
  selectMembershipsData,
} from "../../../../slices/membershipsSlice";
import {
  selectEnquiryData,
  selectEnquiryStatus,
  fetchEnquiryData,
} from "../../../../slices/enquirySlice";
import "./Dashboard.css";
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const membersData = useSelector(selectMembersData) || [];

  const membersStatus = useSelector(selectMembersStatus);
  const membershipsData = useSelector(selectMembershipsData) || [];
  const enquiryData = useSelector(selectEnquiryData) || [];
  const enquiryStatus = useSelector(selectEnquiryStatus);
  const [membershipsRows, setMembershipsRows] = useState([]);
  const isMobile = window.innerWidth <= 768;
  const lineChartRef = useRef(null);
  const barChartRef = useRef(null);
  const chartInstances = useRef([]);
  const monthcollection = useSelector(
    (state) => state.members.getMonthlyCollection
  );
  console.log("Month Collection:", monthcollection);
  useEffect(() => {
    dispatch(fetchMonthlyCollectionData()).unwrap();
  }, []);

  useEffect(() => {
    if (membersStatus === "idle") dispatch(fetchMembersData());
  }, [dispatch, membersStatus]);
  // const memberMemberships = useSelector(selectMembersMembershipData);
  // const partiallyPaidMembers = useSelector(selectPartiallyPaidMembers);
  const Data = useSelector(selectpayment);
  console.log(Data);

  useEffect(() => {
    dispatch(getMembersMembership());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchMembershipsData());
  }, [dispatch]);

  useEffect(() => {
    if (enquiryStatus === "idle") dispatch(fetchEnquiryData());
  }, [dispatch, enquiryStatus]);

  useEffect(() => {
    if (membershipsData) setMembershipsRows(membershipsData);
  }, [membershipsData]);

  useEffect(() => {
    const loadChartJs = async () => {
      if (!window.Chart) {
        const existingScript = document.querySelector(
          'script[src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.9.4/Chart.js"]'
        );
        if (!existingScript) {
          const script = document.createElement("script");
          script.src =
            "https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.9.4/Chart.js";
          script.async = true;
          script.onload = initializeCharts;
          document.body.appendChild(script);
        } else {
          existingScript.onload = initializeCharts;
        }
      } else {
        initializeCharts();
      }
    };

    const calculateMonthlyCounts = (data, dateKey) => {
      const counts = new Array(12).fill(0);
      const currentDate = new Date();
      data.forEach((item) => {
        const date = new Date(item[dateKey]);
        if (!isNaN(date)) {
          const diffInMonths =
            currentDate.getMonth() -
            date.getMonth() +
            12 * (currentDate.getFullYear() - date.getFullYear());
          if (diffInMonths >= 0 && diffInMonths < 12) {
            counts[11 - diffInMonths] += 1;
          }
        }
      });
      return counts;
    };

    const initializeCharts = () => {
      if (chartInstances.current.length > 0) return;

      if (!monthcollection || !Array.isArray(monthcollection)) {
        console.error("Invalid monthcollection data");
        return;
      }

      const transformedMonthCollection = Array.from(
        { length: 12 },
        (_, index) => {
          const monthData = monthcollection?.find(
            (data) => new Date(data.month).getMonth() === index
          );
          return monthData ? monthData.totalFee : 0;
        }
      );

      console.log(
        "Transformed Monthly Collection Data:",
        transformedMonthCollection
      );

      const monthCollectionCopy = monthcollection ? [...monthcollection] : [];

      monthCollectionCopy.sort((a, b) => new Date(a.month) - new Date(b.month));

      const chartLabels = monthCollectionCopy.map((item) => {
        const date = new Date(item.month);
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear().toString().slice(-2);
        return `${month} ${year}`;
      });

      const chartData = monthCollectionCopy.map((item) => item.totalFee);

      const yAxisLabelFontSize = isMobile ? 10 : 18;
      const titleFontSize = isMobile ? 12 : 20;
      const xAxisLabelFontSize = isMobile ? 10 : 16;

      if (lineChartRef.current) {
        const lineCtx = lineChartRef.current.getContext("2d");
        chartInstances.current.push(
          new window.Chart(lineCtx, {
            type: "line",
            data: {
              labels: chartLabels,
              datasets: [
                {
                  label: "Monthly Collections",
                  fill: false,
                  lineTension: 0,
                  backgroundColor: "rgba(0,0,255,1.0)",
                  borderColor: "rgba(0,0,255,0.1)",
                  data: chartData,
                },
              ],
            },
            options: {
              title: {
                display: true,
                text: "Monthly Collections",
                fontSize: titleFontSize,
              },
              legend: { display: false },
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: "₹",
                      fontSize: yAxisLabelFontSize,
                    },
                    ticks: {
                      beginAtZero: true,
                      callback: function (value) {
                        if (value >= 1000) {
                          return (value / 1000).toFixed(0) + "k";
                        } else if (value >= 100000) {
                          return (value / 100000).toFixed(0) + "L";
                        }
                        return value;
                      },
                    },
                  },
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: "Months",
                      fontSize: xAxisLabelFontSize,
                    },
                  },
                ],
              },
            },
          })
        );
      }

      const monthlyEnquiries = calculateMonthlyCounts(
        enquiryData,
        "enquiry_date"
      );

      const last12MonthsLabels = Array.from({ length: 12 }, (_, index) => {
        const date = new Date();
        date.setMonth(date.getMonth() - (11 - index));
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear().toString().slice(-2);
        return `${month}${year}`;
      });

      if (barChartRef.current) {
        const barCtx = barChartRef.current.getContext("2d");
        chartInstances.current.push(
          new window.Chart(barCtx, {
            type: "bar",
            data: {
              labels: last12MonthsLabels,
              datasets: [
                {
                  label: "Enquiries Count",
                  backgroundColor: "rgba(75,192,192,0.4)",
                  borderColor: "rgba(75,192,192,1)",
                  borderWidth: 1,
                  data: monthlyEnquiries,
                },
              ],
            },
            options: {
              title: {
                display: true,
                text: "Monthly Enquiries",
                fontSize: titleFontSize,
              },
              legend: { display: false },
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: "No. of Enquiries",
                      fontSize: yAxisLabelFontSize,
                    },
                    ticks: {
                      beginAtZero: true,
                      stepSize: 1,
                    },
                  },
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: "Months",
                      fontSize: xAxisLabelFontSize,
                    },
                  },
                ],
              },
            },
          })
        );
      }
    };

    loadChartJs();

    return () => {
      chartInstances.current.forEach((chart) => chart.destroy());
      chartInstances.current = [];
    };
  }, [membershipsData, enquiryData]);

  const totalMembers = membersData.length;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const totalEnquiriesThisMonth = enquiryData.filter((enquiry) => {
    const date = new Date(enquiry.enquiry_date);
    return (
      date.getFullYear() === currentYear && date.getMonth() === currentMonth
    );
  }).length;

  const activeMembersCount = membersData.filter(
    (member) => member.status
  ).length;

  const MembersmembershipExpiryThisWeek = membershipsData.filter(
    (membership) => {
      const today = new Date();
      const expiryDate = new Date(membership.membership_enddate);
      const startDate = new Date(membership.membership_startdate);

      const daysToEnd = Math.ceil((expiryDate - today) / (1000 * 60 * 60 * 24));
      const daysFromStart = Math.ceil(
        (today - startDate) / (1000 * 60 * 60 * 24)
      );

      return daysToEnd >= 0 && daysToEnd <= 7 && daysFromStart >= 0;
    }
  ).length;

  const filteredData = Object.values(Data || {}).filter(
    (item) => parseFloat(item.balance_amount) > 0
  );

  const wrapTitle = (title) => {
    return `${title}`;
  };

  const stats = [
    // { title: "All Members", value: totalMembers, link: "/adminapp/members" },
    {
      title: "Active Members",
      value: activeMembersCount,
      link: "/adminapp/members",
    },
    {
      title: "No of Payments Pending",
      value: filteredData.length,
      link: "/adminapp/members",
    },
    {
      title: "Members membership Expiry This Week",
      value: MembersmembershipExpiryThisWeek,
      link: "/expiring-memberships",
    },
    {
      title: wrapTitle("Total Enquiry This Month"),
      value: totalEnquiriesThisMonth,
      link: "/adminapp/enquiries",
    },
  ];

  return (
    <Box
      sx={{
        background: "#fafafb",
        padding: "20px",
        minHeight: "100vh",
        width: "100%",
        margin: "auto",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          marginBottom: "20px",
          textAlign: "center",
          fontWeight: "bold",
          color: "#132E49",
        }}
      >
        DASHBOARD
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {stats.map((stat, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={index}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Link
              to={stat.link}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <Card
                sx={{
                  background: "#e3d026",
                  borderRadius: "10px",
                  boxShadow: 3,
                  padding: "15px",
                  width: "100%",
                  maxWidth: { xs: "350px", sm: "300px", md: "280px" },
                  height: { xs: "200px", sm: "220px", md: "250px" },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: "auto",
                  width: { xs: "90%", sm: "85%", md: "100%" },
                }}
              >
                <CardContent
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      marginBottom: "10%",
                      fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
                    }}
                  >
                    {stat.value}
                  </Typography>

                  <Divider
                    sx={{
                      width: { xs: "160px", sm: "180px" },
                      height: "4px",
                      backgroundColor: "#fff",
                      marginBottom: "20px",
                    }}
                  />

                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: { xs: "1rem", sm: "1.2rem" },
                    }}
                  >
                    {stat.title}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
      <Divider
        sx={{
          marginTop: "40px",
          marginBottom: "40px",
          borderColor: "#132E49",
          borderWidth: "2px",
        }}
      />
      <Grid
        container
        spacing={4}
        justifyContent="center"
        sx={{ marginLeft: "-40px" }}
      >
        <Grid item xs={12} md={6}>
          <div className="main_Chart">
            <canvas
              ref={lineChartRef}
              width="500"
              height="400"
              style={{ display: "block" }}
            ></canvas>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="main_Chart">
            <canvas
              ref={barChartRef}
              width="500"
              height="400"
              style={{ display: "block" }}
            ></canvas>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
