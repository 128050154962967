import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import AnalyticCard from "../cards/AnalyticCard";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Divider,
} from "@mui/material";
import MainCard from "../cards/MainCard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMembersData,
  selectMembersData,
  selectMembersStatus,
  setMembersData,
  selectpayment,
} from "../../../../slices/membersSlice";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Members = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const Data = useSelector(selectpayment);
  console.log("filterdata", Data);
  const membersData = useSelector(selectMembersData);
  console.log("members", membersData);
  const membersStatus = useSelector(selectMembersStatus);
  const activeMembers = membersData.filter((member) => member?.status).length;
  const [isActive, setIsActive] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [selectedMembers, setSelectedMembers] = useState(membersData); // Stores filtered members

  const status = membersData ? "Active" : "Inactive";

  const handletoggle = (value) => {
    setIsActive(value);
  };

  useEffect(() => {
    setSelectedMembers(membersData);
  }, [membersData]);

  useEffect(() => {
    console.log("memberStatus: ", membersStatus);
    if (membersStatus === "idle") {
      dispatch(fetchMembersData());
    } else {
      // Update membersData if needed
      dispatch(setMembersData(membersData));
    }
  }, [dispatch, membersStatus, setMembersData]);

  useEffect(() => {
    dispatch(fetchMembersData());
  }, []);

  const handleRowClick = (params, event, details) => {
    const selectedMemberId = params.row.id;
    navigate(`/adminapp/memberDetails/${selectedMemberId}`);
  };

  const handleRows = (status) => {
    return status ? membersData : membersData.filter((member) => member.status);
  };

  const handleStatusRows = (paymentStatus) => {
    return paymentStatus === "full"
      ? membersData.filter((member) => member.balance_amount === 0)
      : membersData.filter((member) => member.balance_amount > 0);
  };

  const handlePaymentStatusChange = (paymentStatus) => {
    setPaymentStatus(paymentStatus);

    let filteredMembers;
    if (paymentStatus === "full") {
      filteredMembers = membersData.filter(
        (member) => Number(member.balance_amount) === 0 // Ensure balance_amount is a number
      );
    } else if (paymentStatus === "partial") {
      filteredMembers = membersData.filter(
        (member) => Number(member.balance_amount) > 0
      );
    } else {
      filteredMembers = membersData; // Show all members if "All" is selected
    }

    setSelectedMembers(filteredMembers);
  };

  if (membersStatus === "loading") {
    return <div>Loading...</div>;
  }

  const getFilteredMembers = () => {
    return membersData.filter((member) => {
      const matchesActiveStatus = !isActive ? member.status : true;
      const matchesPaymentStatus =
        paymentStatus === "full"
          ? Number(member.balance_amount) === 0
          : paymentStatus === "partial"
          ? Number(member.balance_amount) > 0
          : membersData;
      return matchesActiveStatus && matchesPaymentStatus;
    });
  };

  const membersColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 85,
    },
    {
      field: "name",
      headerName: "Name",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.name}
          </Typography>
        );
      },
    },
    {
      field: "contact_no",
      headerName: "Contact No",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 140,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.contact_no}
          </Typography>
        );
      },
    },
    {
      field: "email_id",
      headerName: "Email ID",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 170,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.row.email_id}
        </Typography>
      ),
    },

    {
      field: "Membership_enddate",
      headerName: "Membership End Date",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 210,
      renderCell: (params) => {
        const endDate = params.row.membership_enddate;
        return endDate ? dayjs(endDate).format("DD/MM/YYYY") : "N/A";
      },
    },

    {
      field: "trainer_name",
      headerName: "Trainer",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 120,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.row.trainer_name}
        </Typography>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 110,
      renderCell: (params) => {
        const status = params.row.status;
        return status ? (
          <Chip label="Active" color="success" />
        ) : (
          <Chip label="Inactive" color="error" />
        );
      },
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      width: isSmallScreen ? 100 : 120,
      minWidth: 120,
      renderCell: (params) => {
        const balanceAmount = parseFloat(params.row.balance_amount) || 0;
        const paymentStatus =
          balanceAmount === 0 ? "Fully Paid" : "Partially Paid";
        return (
          <Chip
            label={paymentStatus}
            color={paymentStatus === "Fully Paid" ? "success" : "warning"}
          />
        );
      },
    },
  ];

  const addNewMember = () => {
    navigate(`/adminapp/memberDetails/`);
  };

  return (
    <Grid
      container
      rowSpacing={4.5}
      columnSpacing={2.75}
      sx={{ background: "#fafafb" }}
    >
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: -2.25 }}
      >
        <Typography variant="h5">Members</Typography>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            variant="contained"
            startIcon={<PersonAddIcon />}
            onClick={() => addNewMember()}
          >
            New Member
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard title="Members" count={membersData.length} extra="5" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard title="Active Members" count={activeMembers} extra="5" />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="flex-end"
          >
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={paymentStatus}
                onChange={(e) => handlePaymentStatusChange(e.target.value)}
              >
                <Typography variant="body1" sx={{ mr: 2, padding: "10px" }}>
                  Payment Status:
                </Typography>
                <FormControlLabel
                  value="full"
                  control={<Radio />}
                  label="Full"
                  onChange={() => handlePaymentStatusChange("full")}
                />
                <FormControlLabel
                  value="partial"
                  control={<Radio />}
                  label="Partial"
                  onChange={() => handlePaymentStatusChange("partial")}
                />
              </RadioGroup>
            </FormControl>
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

            {/* Toggle Switch for Active Members */}
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive}
                    onChange={(e) => handletoggle(e.target.checked)}
                    color="primary"
                    inputProps={{ "aria-label": "toggle switch" }}
                  />
                }
                label={!isActive ? "Active Members" : "All Members"}
              />
            </Grid>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 2 }} content={false}>
          <Box sx={{ height: 400, width: 1 }}>
            <DataGrid
              columns={membersColumns}
              // rows={handleRows(isActive) || handleStatusRows(paymentStatus)}
              rows={getFilteredMembers()}
              pageSize={50}
              onRowClick={handleRowClick}
              initialState={{
                sorting: {
                  sortModel: [{ field: "id", sort: "desc" }],
                },
              }}
            />
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Members;
