import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { isAuthenticated: !!localStorage.getItem("accessToken") },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      localStorage.setItem("expiresIn", Date.now() + 60 * 60 * 1000); // 1 hour
    },
    logout: (state) => {
      state.isAuthenticated = false;
      localStorage.clear();
    },
    refreshAccessToken: (state) => {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) return;

      fetch("/token", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: refreshToken }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.accessToken) {
            localStorage.setItem("accessToken", data.accessToken);
            localStorage.setItem("expiresIn", Date.now() + 60 * 60 * 1000);
            state.isAuthenticated = true;
          }
        })
        .catch(() => {
          state.isAuthenticated = false;
          localStorage.clear();
        });
    },
  },
});

export const { login, logout, refreshAccessToken } = authSlice.actions;
export default authSlice.reducer;
